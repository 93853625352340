<template>
<section class="t-rr-s-user-setting-wrapper">
  <div class="t-rr-s-user-setting-header">
    <div class="t-rr-s-user-setting-header-title">
      <h5>{{$t('Settings.Support')}}</h5>
    </div>
    
  </div>
</section>
</template>
<script>

export default {

}
</script>
<style lang="scss">

@import 'src/assets/css/users-settings.scss';

</style>
